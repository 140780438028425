<script>
//import this.$axios.get from 'this.$axios.get';
const url = process.env.VUE_APP_API_URL;
import CadastrarGrupoDeFiltros from "@/components/CadastrarGrupoDeFiltros/CadastrarGrupoDeFiltros.vue";

export default {
  components: {
    CadastrarGrupoDeFiltros,
  },

  data() {
    return {
      showHoursPopUp: false,
      filtrargrupo: [],
      selectedRows: [],
      dadosParaExcluir: [],
      idEmpresa: null,
      marcarTodos: false,
      searchQuery: "",
      idParaEditarGrupo: null,
    };
  },
  async created() {
    if (this.$route.query.openPopup === "true") {
      this.showHoursPopUp = true;
    }
    await this.fetchGrupos();
  },
  computed: {
    gruposFiltrados() {
      return this.filteredGrupos();
    },
  },
  methods: {
    async fetchGrupos() {
      try {
        const localStorageObject = JSON.parse(localStorage.getItem("User"));
        this.idEmpresa = localStorageObject.Id;
        if (this.idEmpresa) {
          const response = await this.$axios.get(
            url + "/filter/group/company",
            {
              params: { idCompany: this.idEmpresa },
            }
          );
          this.filtrargrupo = response.data;
        }
      } catch (error) {
        console.error("Erro ao buscar grupos de filtros:", error);
        AlertDataSend("Erro ao carregar grupos de filtros.");
      }
    },
    filteredGrupos() {
      if (!this.searchQuery) return this.filtrargrupo;
      return this.filtrargrupo.filter((grupo) =>
        grupo.nome.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    openModal() {
      this.showHoursPopUp = true;
      this.idParaEditarGrupo = null;
    },
    closeModal() {
      this.showHoursPopUp = false;
    },
    adicionarGrupo(grupo) {
      const index = this.filtrargrupo.findIndex((g) => g.id === grupo.id);
      if (index !== -1) {
        this.filtrargrupo[index] = grupo;
      } else {
        this.filtrargrupo.push(grupo);
      }
      this.fetchGrupos();
    },
    selecionartodos() {
      if (this.marcarTodos) {
        this.selectedRows = this.filtrargrupo.map((grupo) => grupo.id);
      } else {
        this.selectedRows = [];
      }
    },
    async editarGrupo(grupo) {
      this.idParaEditarGrupo = grupo.id;
      this.showHoursPopUp = true;
      await this.fetchGrupos();
    },
    showDeleteModal(id) {
      this.$axios
        .delete(url + "/filter/group", {
          params: {
            idFilterGroup: id,
          },
        })
        .then((response) => {
          this.dados = response.data;
          this.filtrargrupo = this.filtrargrupo.filter(
            (item) => item.id !== id
          );
        })
        .catch((error) => {
          console.error("Erro ao excluir grupo", error);
        });
    },
    async excluirSelecionados() {
      try {
        for (let id of this.selectedRows) {
          await this.$axios.delete(url + "/filter/group", {
            params: { idFilterGroup: id },
          });
          this.filtrargrupo = this.filtrargrupo.filter(
            (grupo) => grupo.id !== id
          );
        }
        this.selectedRows = [];
        AlertDataSend("Grupos excluídos com sucesso!");
      } catch (error) {
        console.error("Erro ao excluir grupos", error);
        AlertDataSend("Erro ao excluir os grupos.");
      }
    },
    verificaSelecaoTotal() {
      this.marcarTodos = this.selectedRows.length === this.filtrargrupo.length;
    },
  },
  watch: {
    selectedRows(newSelectedRows) {
      this.verificaSelecaoTotal();
    },
  },
};
</script>
<template>
  <main class="base">
    <header class="caixaTitulo">
      <div class="caixaTitulo_titulo">Grupo de filtros</div>
      <button class="caixaTitulo_botao" @click="openModal()">
        <i class="fa-solid fa-plus"></i>
        Adicionar Grupo
      </button>
    </header>
    <section class="conteudo">
      <div class="tabela">
        <div style="display: flex">
          <div class="icon-style">
            <i class="fa-solid fa-filter"></i>
          </div>
          <input
            style="margin-left: 17px"
            type="text"
            placeholder="Pesquisar"
            class="pesquisa"
            v-model="searchQuery"
          />
          <button class="botaoPesquisa">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <table cellspacing="0">
          <thead>
            <tr class="nomedascolunas">
              <th style="width: 5%">
                <input
                  style="width: 80%; text-align: center"
                  type="checkbox"
                  @change="selecionartodos"
                  v-model="marcarTodos"
                />
              </th>
              <th>Editar</th>
              <th @click="ordernarOrdemAlfabetica">
                <span
                  >Nome do grupo
                  <span v-if="estaOrdemAlfabetica">
                    <i class="fa-solid fa-arrow-up-wide-short"></i>
                  </span>
                  <span v-else>
                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                  </span>
                </span>
              </th>
              <th style="width: 10%; align-items: center">
                <span
                  style="
                    width: 10%;
                    align-items: center;
                    text-align: center;
                    margin-left: 2.9vh;
                  "
                >
                  Excluir
                </span>
                <button
                  class="btn_rm"
                  @click="excluirSelecionados()"
                  style="
                    font-size: 1rem;
                    color: #2bb542;
                    font-weight: bold;
                    text-align: center;
                    margin-left: -4.1%;
                  "
                  v-if="selectedRows.length"
                >
                  ({{ selectedRows.length }})
                  <i class="fa-solid fa-trash"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(row, index) in gruposFiltrados" :key="index">
            <tr class="back-color">
              <td class="quina" style="width: 5%">
                <input
                  type="checkbox"
                  style="width: 100%; margin-left: -10px"
                  v-model="selectedRows"
                  :value="row.id"
                />
              </td>
              <td class="quina">
                <button class="btn_edit" @click="editarGrupo(row)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
              <td>{{ row.nome }}</td>
              <td class="quina">
                <button
                  class="btn_edit"
                  style="color: red; font-size: 1rem"
                  @click="showDeleteModal(row.id)"
                >
                  <i class="fa fa-xmark"></i>
                </button>
              </td>
            </tr>
            <tr class="line">
              <td colspan="8" class="naoSelecionavel">a</td>
            </tr>
          </tbody>
        </table>
        <div v-if="filteredGrupos().length === 0" class="nadaEncontrado">
          <div>
            Nenhum grupo foi encontrado
            <i class="fa-solid fa-plug-circle-exclamation"></i>
          </div>
        </div>
        <div class="overlay" v-if="loading">
          <i id="icon-style" class="fa-solid fa-gear"></i>
          <i id="icon-stylee" class="fa-solid fa-gear"></i>
        </div>
      </div>
    </section>
    <CadastrarGrupoDeFiltros
      v-if="showHoursPopUp"
      @close="closeModal"
      @grupo-cadastrado="adicionarGrupo"
      :idEmpresa="idEmpresa"
      :idGrupo="idParaEditarGrupo"
    />
  </main>
</template>
  
<style scoped>
.tabela {
  width: 100%;
  height: 70vh;
  font-family: Arial, Helvetica, sans-serif;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay .fa-gear {
  animation: spin 1s linear infinite;
}
.style-msg {
  max-width: 18vw;
  overflow: hidden;
  min-width: 18vw;
  white-space: nowrap;
}
.style-name {
  margin-left: -19.3vw;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
#icon-style {
  align-items: center;
  font-size: 3rem;
  color: white;
}
#icon-stylee {
  font-size: 2rem;
  color: white;
  align-items: end;
}
h1 {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.caixaTitulo_botao:hover {
  background-color: #2bb542d7;
}

th,
td {
  padding: 5px;
  border-spacing: 35px;
}

.caixaTitulo_botao {
  position: relative;
  display: inline-block;
}

.caixaTitulo_botao:hover .DicaTexto {
  visibility: visible;
}

.icon-style {
  padding-left: 2.3vh;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 200px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.DicaTexto:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
}

.back-color:hover {
  background-color: rgba(92, 90, 90, 0.1);
}

table {
  width: 100%;
}

thead {
  border-bottom: 1px solid #b3b3b3;
}

tr td {
  border-left: 1px solid #b3b3b3;
}

.nomedascolunas {
  text-align: left;
  color: #2bb542;
  font-family: initial;
}

thead {
  margin-bottom: 5px;
}

.nomedascolunas th {
  border-bottom: 1px solid #2bb542;
}

.pesquisa {
  width: 20%;
  height: 30px;
  padding-left: 5px;
  display: flex;
}

.line {
  padding: 0;
  color: transparent;
  border-left: none;
  border-right: none;
}

.line td {
  border-bottom: 1px solid #b3b3b3;
  border-left: none;
  height: 1px;
  padding: 0;
}

.line_vazia td {
  height: 1px;
  border: none;
}

.quina {
  width: 15px;
  text-align: center;
}
.quinaa {
  width: 4vw;
  text-align: center;
  border: none;
}

.botaoPesquisa {
  background-color: white;
  padding: 6px;
  border: 0px;
  cursor: pointer;
}

.nadaEncontrado {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
  color: #c1c1c1;
}

.btn_rm {
  font-size: 1rem;
  color: #2bb542;
  font-weight: bold;
  text-align: center;
  margin-left: 1.7vw;
}

.naoSelecionavel {
  font-size: 2px;
  color: transparent;

  -webkit-touch-callout: none;
  /* iPhone OS, Safari */
  -webkit-user-select: none;
  /* Chrome, Safari 3 */
  -khtml-user-select: none;
  /* Safari 2 */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}
</style>
  
<script>
import SelectSearch from "@/components/Select-Search/SelectSearch.vue";
import { mask } from "vue-the-mask";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { AlertDataSend } from "../../components/alert";
import SelectDatasend from "@/components/Select-Search/SelectDatasend.vue";
import SelectTypeUser from "@/components/SelectOrCreate-Remove/SelectTypeUser.vue";
import SelectGroupFilter from "@/components/SelectOrCreate-Remove/SelectGroupFilter.vue";

import {
  getUsuarioCadastrado,
  postCadastroBanco,
} from "@/components/cadastroUsuario/api.js";

import { limpaMascara } from "@/components/cadastroUsuario/util.js";
import { RouterLink } from "vue-router";

const url = process.env.VUE_APP_API_URL;
const urlComunicationQlik = process.env.VUE_APP_API_URL_QLIK;

export default {
  directives: { mask },
  props: {
    idEmpresa: String,
    idGrupo: String,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    SelectSearch,
    AlertDataSend,
    SelectDatasend,
    SelectTypeUser,
    SelectGroupFilter,
  },
  data() {
    return {
      idUsuario: null,
      idEmpresa: null,
      showHoursPopUp: false,
      filtrargrupo: [],
      filtro: [],
      filtros: [],
      painelTitle: "Selecione",
      paineis: "",
      painel: "",
      typeUser: "",
      bookmarks: "",
      bookmark: "",
      bookmarkTitle: "Selecione",
      campos: "",
      campo: "",
      campoTitle: "Selecione",
      conteudos: "",
      conteudo: "",
      conteudoTitle: "Selecione",
      removeSelecao: false,
      nome: null,
      email: null,
      numeroWhatsapp: "",
      assistente: "",
      loading: false,
      idExpediente: null,
      filtrarexpediente: [],
      addExpediente: true,
      expedienteTitle: "Adicionar Expediente",
      groupFilterTitle: " Grupos de filtros",
      expedienteRemove: false,
      msg: {},
      typeUserTitle: "Tipo de usuarios",
      idGroupFilter: null,
      idTypeUser: null,
      groupFilter: "",
    };
  },
  validations() {
    return {
      nome: { required },
      email: {},
      numeroWhatsapp: { required, minLengthRef: minLength(15) },
      assistente: { required },
    };
  },
  async created() {
    const localStorageObject = await JSON.parse(localStorage.getItem("User"));
    this.idEmpresa = localStorageObject.Id;
    if (this.$route.query.id) {
      this.alreadyRegisteredUser();
    }
    const panel = await this.getPanel();
    this.paineis = panel.data;

    await this.expedienteCadastrado();
    await this.typeUserCadastrado();
    await this.groupFilters();

    document.addEventListener("keydown", this.handleEscKey);
  },
  methods: {
    async alreadyRegisteredUser() {
      const dadosJacadastrado = await getUsuarioCadastrado(
        this.$route.query.id
      );
      const ordenarFiltro = dadosJacadastrado.filtro.sort((a, b) => {
        let panelComparison = a.painel.title.localeCompare(b.painel.title);

        if (panelComparison === 0) {
          let sheetComparison = a.sheet.title.localeCompare(b.sheet.title);
          if (sheetComparison === 0) {
            return a.objeto.title.localeCompare(b.objeto.title);
          }
          return sheetComparison;
        }
        return panelComparison;
      });
      this.nome = dadosJacadastrado.nome;
      this.email = dadosJacadastrado?.email || "";
      this.numeroWhatsapp = dadosJacadastrado.numeroWhatsapp;
      this.assistente = dadosJacadastrado?.assistente || "";
      this.idUsuario = this.$route.query.id;
      this.typeUserTitle =
        dadosJacadastrado?.typeUser?.nome || "Tipo de usuarios";
      this.idTypeUser = dadosJacadastrado?.typeUser?.id || null;
      this.groupFilterTitle =
        dadosJacadastrado?.filterGroup?.nome || " Grupos de filtros";
      this.idGroupFilter = dadosJacadastrado?.filterGroup?.id || null;
      this.filtro = ordenarFiltro
      if (dadosJacadastrado.expediente !== null) {
        this.updateExpedienteTitle(dadosJacadastrado.expediente[0]);
      }
    },
    async adcionar() {
      if (!this.campo || this.campo === "") {
        this.campo = { title: "*****" };
      }
      if (!this.conteudo || this.conteudo === "") {
        this.conteudo = { title: "*****" };
      }
      if (!this.bookmark) {
        this.bookmark = { title: "*****" };
      }
      if (this.painel === "") {
        AlertDataSend("Por favor, selecione um painel.", "alert");
        return;
      }
      if (this.campo && !this.conteudo) {
        AlertDataSend("Não é possível cadastrar campo sem conteúdo");
      } else {
        const dados = {
          painel: this.painel,
          bookmark: this.bookmark,
          campo: this.campo,
          conteudo: this.conteudo,
        };
        const verify = this.filtro.some((item) => {
          if (
            item.painel.title === dados.painel.title &&
            item.bookmark.title === dados.bookmark.title &&
            item.campo.title === dados.campo.title &&
            item.conteudo.title === dados.conteudo.title
          ) {
            return true;
          }
          return false;
        });
        if (!verify) {
          this.filtro.unshift(dados);
        } else {
          AlertDataSend("Filtro já cadastrado");
        }
      }
    },
    async getPanel() {
      const jsonData = { idCompany: this.idEmpresa };
      try {
        const panel = await this.$axios.get(
          urlComunicationQlik + "/panel/all",
          {
            params: jsonData,
          }
        );

        return panel;
      } catch (error) {
        AlertDataSend("Não foi possivel carregar os painéis.");
        setTimeout(() => {
          AlertDataSend("Preencha seus dados em conexão");
        }, 2800);
      }
    },
    limparPastaEObjeto() {
      if (this.sheets) {
        this.sheets = null;
        this.sheet = "";
        this.sheetTitle = "Selecione";
        this.objetos = "";
        this.objeto = "";
        this.objetoTitle = "Selecione";
        this.campos = null;
        this.conteudos = null;
        this.campoTitle = "Selecione";
        this.conteudoTitle = "Selecione";
        this.campo = null;
        this.conteudo = null;
        this.bookmark = "";
        this.bookmarks = "";
        this.bookmarkTitle = "Selecione";
        this.blockFieldAndContent = false;
        this.blockBookmark = false;
      }
    },
    async getBookmark() {
      const jsonData = { idCompany: this.idEmpresa, idPanel: this.painel.id };
      await this.$axios
        .get(urlComunicationQlik + "/bookmark/all", {
          params: jsonData,
        })
        .then((response) => {
          this.bookmarks = response.data;
        })
        .catch((error) => {});
    },
    resetBookmark() {
      this.bookmark = "";
      this.bookmarkTitle = "Selecione";
      this.blockFieldAndContent = false;
    },
    async expedienteCadastrado() {
      const localStorageObject = JSON.parse(localStorage.getItem("User"));
      this.idEmpresa = localStorageObject.Id;

      if (this.idEmpresa) {
        try {
          const response = await this.$axios.get(url + "/expediente", {
            params: { id: this.idEmpresa },
          });

          this.filtrarexpediente = response.data;
        } catch (error) {
          console.error("Erro ao carregar os expedientes:", error);
        }
      }
    },
    async typeUserCadastrado() {
      const localStorageObject = JSON.parse(localStorage.getItem("User"));
      this.idEmpresa = localStorageObject.Id;
      if (this.idEmpresa) {
        try {
          const response = await this.$axios.get(url + "/type/user", {
            params: { idCompany: this.idEmpresa },
          });
          this.filtroTypeUser = response.data;
          if (this.filtroTypeUser.length > 0) {
            this.typeUser = this.filtroTypeUser;
          }
        } catch (error) {
          console.error("Erro ao carregar os Tipos de usuário:", error);
        }
      }
    },
    async groupFilters() {
      const localStorageObject = JSON.parse(localStorage.getItem("User"));
      this.idEmpresa = localStorageObject.Id;

      if (this.idEmpresa) {
        try {
          const response = await this.$axios.get(
            url + "/filter/group/company",
            {
              params: { idCompany: this.idEmpresa },
            }
          );
          this.filtroGroup = response.data;
          if (this.filtroGroup.length > 0) {
            this.groupFilter = this.filtroGroup;
          }
        } catch (error) {
          console.error("Erro ao carregar os grupos de filtros:", error);
        }
      }
    },
    resetCampo() {
      this.campo = "";
      this.campoTitle = "Selecione";
      this.blockBookmark = false;
      this.liparConteudo();
    },
    resetConteudo() {
      this.conteudo = "";
      this.conteudoTitle = "Selecione";
    },
    updateCampo(option) {
      this.campo = option;
      this.campoTitle = option.title;
      this.blockBookmark = true;
    },
    updateConteudo(option) {
      this.conteudo = option;
      this.conteudoTitle = option.title;
    },
    async updateBookmark(option) {
      this.bookmark = await option;
      this.bookmarkTitle = this.bookmark.title;
      this.blockFieldAndContent = true;
    },
    async atualizarSheet() {
      this.spinner = true;
      const jsonData = { idCompany: this.idEmpresa, idPanel: this.painel.id };

      const reqSheet = await this.$axios.get(
        urlComunicationQlik + "/sheet/all",
        {
          params: jsonData,
        }
      );
      this.sheets = await reqSheet.data;
    },
    updatePainel(option) {
      this.painel = option;
      this.painelTitle = option.title;
    },
    updateTypeUserTitle(option) {
      this.typeUser = option;
      this.typeUserTitle = option.nome;
      this.idTypeUser = option.id;
    },
    updateGroupFilterTitle(option) {
      this.groupFilter = option;
      this.groupFilterTitle = option.nome;
      this.idGroupFilter = option.id;
    },
    updateExpedienteTitle(option) {
      if (option >= 0) {
        this.expedienteRemove = true;
      } else {
        this.expedienteRemove = true;
      }
      this.expedienteTitle = option.nome;
      this.idExpediente = option.id;
    },
    limparBookmark() {
      if (this.bookmarks) {
        this.bookmarks = "";
        this.bookmark = "";
        this.bookmarks = "Selecione";
      }
    },
    liparConteudo() {
      if (this.conteudos) {
        this.conteudos = "";
        this.conteudo = "";
        this.conteudoTitle = "Selecione";
      }
    },
    updatePasta(option) {
      this.sheet = option;
      this.sheetTitle = option.title;
    },
    remover_filtro(index) {
      this.filtro.splice(index, 1);
    },
    async atualizarField() {
      this.spinner = true;
      const jsonData = { idCompany: this.idEmpresa, idPanel: this.painel.id };

      const reqField = await this.$axios.get(
        urlComunicationQlik + "/field/all",
        {
          params: jsonData,
        }
      );
      this.campos = await reqField.data;
    },
    async getContent() {
      this.loading = true;
      this.conteudo = "";
      const objJson = {
        idPanel: this.painel.id,
        idField: this.campo.title,
        idCompany: this.idEmpresa,
        qtd: 0,
      };
      const response = await this.$axios.post(
        urlComunicationQlik + "/content/all",
        objJson
      );
      response.data.sort((a, b) => {
        let numA = parseInt(a.title, 10);
        let numB = parseInt(b.title, 10);

        return numA - numB;
      });
      if (!this.conteudos) {
        this.conteudos = response.data;
      } else {
        for (let data of response.data) {
          this.conteudos.push(data);
        }
      }
      this.loading = false;
    },
    async atualizar() {
      const user = {
        idEmpresa: this.idEmpresa,
        nome: this.nome,
        idusuario: this.idUsuario,
        email: this.email,
        idExpediente: this.idExpediente,
        numeroWhatsapp: limpaMascara(this.numeroWhatsapp),
        filtro: this.filtro,
        status: this.assistente,
        idGroupFilter: this.groupFilter
          ? this.idGroupFilter
          : this.idGroupFilter,
        idTypeUser: this.typeUser ? this.idTypeUser : this.idTypeUser,
      };
      this.v$.$validate();
      if (this.v$.$error) {
        AlertDataSend("Preencha os campos corretamente", "alert");
        return;
      }
      const id = await postCadastroBanco(user);
      this.idUsuario = id.data;
      this.$root.$emit("SalvarExpediente");
      AlertDataSend("Cadastro alterado com sucesso.");
      console.log(user);

      // if (id && id.response && id.response.status === 409) {
      //   AlertDataSend("Número já está cadastrado.");
      // } else if (!this.$route.query.id) {
      //   this.idUsuario = id;
      //   await this.$root.$emit("SalvarExpediente");
      //   this.$router.push("/userScreen");
      //   await this.$router.push({
      //     name: "userRegistration",
      //     query: { id: id.data },
      //   });
      // } else {
      // }
    },
    async salvar() {
      try {
        const user = {
          idEmpresa: this.idEmpresa,
          idusuario: this.$route.query.id,
          nome: this.nome,
          email: this.email,
          numeroWhatsapp: limpaMascara(this.numeroWhatsapp),
          idExpediente: this.idExpediente,
          filtro: this.filtro,
          status: this.assistente,
          favorito: false,
          idGroupFilter: this.groupFilter
            ? this.idGroupFilter
            : this.idGroupFilter,
          idTypeUser: this.typeUser ? this.idTypeUser : this.idTypeUser,
        };
        this.v$.$validate();
        if (this.v$.$error) {
          AlertDataSend("Preencha os campos corretamente", "alert");
          return;
        }
        const id = await postCadastroBanco(user);
        this.idUsuario = id;
        this.$root.$emit("SalvarExpediente");
        this.$router.push("/userScreen");
      } catch (error) {
        if (error && error.response && error.response.status === 409) {
          AlertDataSend("Número já está cadastrado.");
        } else {
          console.error("Erro ao salvar:", error);
        }
      }
    },
    validateEmail() {
      if (/^[\w+.-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}$/.test(this.email)) {
        this.msg["email"] = "enter a valid email address";
      } else {
        this.msg["email"] = "";
      }
    },
  },
};
</script>
<template>
  <main class="base">
    <section class="conteudo">
      <div class="popup">
        <div class="caixaTitulo_titulo">
          <span>Cadastrar usuário</span>
          <router-link class="btn_sair" to="/userScreen">
            <button class="btn_sair">
              <p id="icon-exit">X</p>
            </button>
            <Message :msg="msg" v-show="msg" id="Mensagem" />
          </router-link>
        </div>
        <div>
          <div class="space-style">
            <p class="style-padrao">Nome</p>
            <p class="style-padrao">Número do Whatsapp</p>
            <p style="margin-right: 1%" class="style-padrao">Status whatsapp</p>
          </div>
          <span class="name-group-style">
            <input
              :class="[{ error: v$.nome.$error }]"
              v-model="v$.nome.$model"
              type="text"
              id="nome"
              class="input_padrao"
              placeholder="Digite o nome"
            />
            <input
              class="input_padrao"
              :class="[{ error: v$.numeroWhatsapp.$error }]"
              v-model="v$.numeroWhatsapp.$model"
              type="tel"
              id="numero"
              v-mask="'55(##)####-#####'"
              placeholder=" 🇧🇷 (DDD) 0000-0000"
              autocomplete="off"
            />
            <select
              :class="[{ error: v$.assistente.$error }]"
              class="input_padrao"
              name="assistente"
              id="assistente"
              v-model="v$.assistente.$model"
              tabindex="0"
            >
              <option value="Ativado" tabindex="1">Ativado</option>
              <option value="Desativado" tabindex="2">Desativado</option>
            </select>
          </span>
        </div>
        <div>
          <div class="space-style">
            <div style="width: 20%">
              <p class="style-padrao" id="style-obgr" v-if="v$.nome.$error">
                Campo obrigatório
              </p>
            </div>
            <div style="width: 20%">
              <p
                class="style-padrao"
                id="style-obgr"
                v-if="v$.numeroWhatsapp.$error"
              >
                Campo obrigatório
              </p>
            </div>
            <div style="width: 20%">
              <p
                style="margin-right: 8%"
                class="style-padrao"
                id="style-obgr"
                v-if="v$.assistente.$error"
              >
                Campo obrigatório
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="space-style">
            <p class="style-padrao">Email</p>
          </div>
          <span class="name-group-stylee">
            <input
              style="padding: 0px 12px"
              for="email"
              required
              v-model="email"
              type="email"
              id="nome"
              class="input_padraoo"
              placeholder="Digite seu e-mail"
              @blur="validateEmail"
            />
          </span>
        </div>
        <div>
          <div class="space-style">
            <p class="style-padrao">Nome do expediente</p>
            <p style="margin-right: 8%" class="style-padrao">
              Vincular grupo de filtro
            </p>
            <p style="margin-right: 1%" class="style-padrao">
              Vincular tipo de usuário
            </p>
          </div>
          <span class="name-group-style">
            <SelectDatasend
              style="width: 17.5vw; z-index: 999"
              :options="filtrarexpediente"
              :addExpediente="addExpediente"
              :selected="expedienteTitle"
              :expedienteRemove="expedienteRemove"
              @input="updateExpedienteTitle"
            >
            </SelectDatasend>
            <SelectGroupFilter
              style="width: 17.5vw; z-index: 999"
              :selected="groupFilterTitle"
              @update:selected="updateGroupFilterTitle"
              v-model="idGroupFilter"
            />
            <SelectTypeUser
              style="width: 17.5vw; z-index: 999"
              :selected="typeUserTitle"
              v-model="idTypeUser"
              @update:selected="updateTypeUserTitle"
            />
          </span>
        </div>
        <div class="form-group">
          <div class="header-group">
            <span style="margin-left: 6%" class="space-labels">PAINEL</span>
            <span class="space-labels">MARCADOR</span>
            <span class="space-labels">CAMPO</span>
            <span class="space-labels">CONTEUDO</span>
          </div>
          <div class="select-group">
            <th class="style-select">
              <SelectSearch
                name="painel"
                tabindex="0"
                @update="
                  limparPastaEObjeto();
                  atualizarSheet();
                  getBookmark();
                  atualizarField();
                "
                @executeSearch="verifyReceivedThetextSearch"
                :options="paineis"
                :selected="painelTitle"
                @input="updatePainel"
                :removeSelecao="removeSelecao"
              >
              </SelectSearch>
            </th>
            <th class="style-select">
              <SelectSearch
                name="bookmark"
                tabindex="2"
                :selected="bookmarkTitle"
                :spinner="spinner"
                @update="
                  getBookmark();
                  limparBookmark();
                "
                @input="updateBookmark"
                :options="bookmarks"
                @without-input="resetBookmark"
                @executeSearch="verifyReceivedThetextSearch"
                :abrirselect="blockBookmark"
              >
              </SelectSearch>
            </th>
            <th class="style-select">
              <SelectSearch
                name="campo"
                tabindex="2"
                @update="
                  getContent();
                  liparConteudo();
                "
                @executeSearch="verifyReceivedThetextSearch"
                :options="campos"
                :selected="campoTitle"
                :spinner="spinner"
                @input="updateCampo"
                @without-input="resetCampo"
                :abrirselect="blockFieldAndContent"
                :removeSelecao="removeSelecao"
              >
              </SelectSearch>
            </th>
            <th class="style-select">
              <SelectSearch
                name="conteudo"
                tabindex="2"
                :options="conteudos"
                :selected="conteudoTitle"
                :spinner="spinner"
                @executeSearch="verifyReceivedThetextSearch"
                @input="updateConteudo"
                :showLoadButton="showLoadButton"
                @obterMaisConteudo="obterMaisConteudos"
                :loading="loading"
                @without-input="resetConteudo"
                :abrirselect="blockFieldAndContent"
                :removeSelecao="removeSelecao"
              >
              </SelectSearch>
            </th>
            <th style="height: 8%">
              <div class="style-adc">
                <button
                  style="border: transparent; background-color: transparent"
                  id="button-add"
                  v-if="!temItensSelecionados"
                  @click="adcionar"
                >
                  <p id="icon-add">+</p>
                </button>
              </div>
            </th>
          </div>
          <div class="lista-container">
            <tbody class="lista-containerr">
              <tr
                class="mutavel"
                v-for="(it, index) in filtro"
                :key="index"
                :ref="'item_' + index"
              >
                <td class="style-mutavel">{{ it.painel.title }}</td>
                <td class="style-mutavel">{{ it.bookmark.title }}</td>
                <td class="style-mutavel">{{ it.campo.title }}</td>
                <td class="style-mutavel">{{ it.conteudo.title }}</td>
                <td>
                  <div class="style-adc">
                    <button id="button-remove" @click="remover_filtro(index)">
                      <i id="icon" class="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </div>
        </div>
        <div class="style-button">
          <span @click="atualizar()" class="input_button_confirm"
            >Confirmar alterações</span
          >
          <span @click="salvar()" class="input_button">Salvar</span>
        </div>
        <!-- <div class="overlay" v-if="loading">
          <i id="icon-style" class="fa-solid fa-gear"></i>
          <i id="icon-stylee" class="fa-solid fa-gear"></i>
        </div> -->
      </div>
    </section>
  </main>
</template>

<style scoped>
#icon-exit {
  font-size: 1.4vw;
  background-color: white;
}
#button-remove {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: red;
  font-size: 1.6vw;
}
.style-adc {
  display: flex;
  margin-right: 1vw;
  background-color: transparent;
  width: 3.5vw;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.overlay .fa-gear {
  animation: spin 1s linear infinite;
}
.overlay {
  position: absolute;
  margin-left: -1%;
  top: 1;
  left: 1;
  width: 42%;
  height: 75%;
  background-color: white;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}
#style-obgr {
  color: red;
  display: block;
  margin-left: 1px;
}
.style-mutavel {
  width: 100%;
  padding: 0vw 2.5vw;
  font-size: 1vw;
  display: flex;
  justify-content: space-between;
}
.btn_sair {
  cursor: pointer;
  background-color: white;
  border: none;
}
.caixaTitulo_titulo {
  display: flex;
  font-size: 1.9rem;
  justify-content: space-between;
  width: 100%;
}
.space-labels {
  color: #2bb542;
  width: 31%;
  font-size: 1.1vw;
  font-weight: 300;
}
.style-padrao {
  font-size: 0.9vw;
  padding: 5px;
}
.space-style {
  display: flex;
  justify-content: space-between;
}
.popup {
  width: 58vw;
  height: 91vh;
  background: white;
  font-size: 1rem;
  border-radius: 10px;
  padding: 2vw;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  top: 6vw;
  left: 32vw;
  z-index: 1;
}
.name-group-style {
  display: flex;
  gap: 1vw;
  width: 100%;
}
.name-group-stylee {
  display: flex;
  gap: 1vw;
  width: 100%;
  height: 5.8vh;
}
.style-select {
  /* width: 30%; */
  margin-left: 3%;
  height: 9.5%;
}
.lista-container {
  display: block;
  width: 10;
  /* max-height: 30.8vh; */
  overflow-y: auto;
  overflow-x: hidden;
}
.lista-containerr {
  display: block;
  width: 10;
  max-height: 20.8vh;
  /* overflow-y: auto;
  overflow-x: hidden; */
}
.input_padrao {
  border: 1px solid #b3b3b3;
  color: black;
  border-radius: 5px;
  flex: 2;
  width: 50%;
  min-width: 0;
}
.input_padraoo {
  border: 1px solid #b3b3b3;
  color: black;
  border-radius: 5px;
  flex: 2;
  width: 100%;
  min-width: 0;
}
.select-style {
  border: 1px solid #b3b3b3;
  color: black;
  border-radius: 5px;
  width: 40%;
  flex: 1;
}
.form-group {
  box-shadow: 1px 1px 5.5px #8b8b8b;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.header-group {
  display: flex;
  justify-content: space-between;

  padding: 10px;
}
.select-group {
  display: flex;
  /* height: 100%; */
  /* gap: 10px; */
  border-bottom: 1px solid #929090;
}
.mutavel {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #929090;
}
.mutavel:hover {
  box-shadow: 1px 1px 5.5px #8b8b8b;
}
.mutavel p {
  display: flex;
}
.conteudo {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.304);
  font-family: Arial, Helvetica, sans-serif;
}
.input_button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bolder;
  height: 40px;
  border-radius: 2px;
  padding-left: 5px;
  text-align: center;
  color: white;
  width: 100%;
  background-color: #2bb542;
  border-radius: 5px;
}
.input_button_confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bolder;
  height: 40px;
  border-radius: 2px;
  padding-left: 5px;
  text-align: center;
  color: black;
  width: 100%;
  background-color: transparent;
  box-shadow: 1px 1px 5.5px #8b8b8b;
  border-radius: 5px;
}

.style-button {
  cursor: pointer;
  display: flex;
  gap: 25vw;
  width: 100%;
}
.style-adc {
  cursor: pointer;
  border: transparent;
  display: flex;
  justify-content: end;
}
#icon-excluir {
  border: none;
  background-color: white;
  color: red;
  border: transparent;
}
#icon-add {
  cursor: pointer;
  border: transparent;
  font-size: 1.8rem;
  border-radius: 10px;
  border: transparent;
  height: 95%;
  width: 1.8vw;
  background-color: #2bb542;
  color: white;
}
</style>
